<template>
  <svg
    v-if="type === 'qs-mark'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM7.99 8.99V11H8.99V15H12.01V13H11L11.01 8.991L7.99 8.99ZM8.99 5V7.019H11.01V5H8.99Z"
      :fill="fill"
    />
  </svg>

  <svg
    v-else-if="type === 'qs-mark-outline'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM2 10.172C2.04732 14.5732 5.64111 18.1095 10.0425 18.086C14.444 18.0622 17.9995 14.4875 17.9995 10.086C17.9995 5.68451 14.444 2.10977 10.0425 2.086C5.64111 2.06246 2.04732 5.59876 2 10V10.172ZM12 15H9V11H8V9H11V13H12V15ZM11 7H9V5H11V7Z"
      :fill="fill"
    />
  </svg>

  <svg
    v-else-if="type === 'outline'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99 20C4.46846 19.9945 -0.00367557 15.5149 2.26684e-06 9.99334C0.00368465 4.47179 4.48179 -0.00183754 10.0033 5.66223e-07C15.5249 0.00183981 20 4.47845 20 10C19.9967 15.5254 15.5154 20.0022 9.99 20ZM2 10.172C2.04732 14.5732 5.64111 18.1095 10.0425 18.086C14.444 18.0622 17.9995 14.4875 17.9995 10.086C17.9995 5.6845 14.444 2.10977 10.0425 2.08599C5.64111 2.06245 2.04732 5.59876 2 10V10.172ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z"
      :fill="fill"
    />
  </svg>

  <svg
    v-else-if="type === 'bold'"
    width="20"
    height="20"
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="exclamation-circle"
    class=""
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      :fill="fill"
      d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
    />
  </svg>

  <svg
    v-else-if="type === 'attention-outline'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.33464 11.3337H9.33464V10.0003H8.66797V7.33366H6.66797V8.66699H7.33464V11.3337Z" :fill="fill" />
    <path d="M7.33464 6.00033H8.66797V4.66699H7.33464V6.00033Z" :fill="fill" />
    <path d="M14.1654 7.99913C14.1616 11.4036 11.4027 14.1626 7.99815 14.1663C4.59265 14.166 1.83203 11.4052 1.83203 7.99967C1.83203 4.59392 4.59294 1.83301 7.9987 1.83301C11.4043 1.83301 14.1651 4.59362 14.1654 7.99913Z" :stroke="fill" />
  </svg>

  <svg
    v-else-if="type === 'attention'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.39677 14.6663C4.76254 14.6663 1.81641 11.6816 1.81641 7.99967C1.81641 4.31778 4.76254 1.33301 8.39677 1.33301C12.031 1.33301 14.9771 4.31778 14.9771 7.99967C14.9732 11.6799 12.0294 14.6623 8.39677 14.6663ZM7.07412 7.32634V8.66634H7.73216V11.333H9.71943V9.99967H9.05481L9.06139 7.32701L7.07412 7.32634ZM7.73216 4.66634V6.01234H9.06139V4.66634H7.73216Z" :fill="fill" />
  </svg>

  <svg
    v-else
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2485 20.5347C4.72569 20.5347 0.248535 16.0575 0.248535 10.5347C0.248535 5.01182 4.72569 0.534668 10.2485 0.534668C15.7714 0.534668 20.2485 5.01182 20.2485 10.5347C20.2425 16.055 15.7689 20.5286 10.2485 20.5347ZM9.24854 13.5347V15.5347H11.2485V13.5347H9.24854ZM9.24854 5.53467V11.5347H11.2485V5.53467H9.24854Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconsInfoCircle',

  props: {
    type: {
      type: String,
      default: 'default',
      validator: value => ['default', 'bold', 'qs-mark', 'qs-mark-outline', 'outline', 'attention', 'attention-outline'].includes(value)
    },

    fill: {
      type: String,
      default: '#0AC16A'
    }
  }
}
</script>
